.portfolio {
    min-height: 100vh;
    padding: 5vh 0vh;
    text-align: center;
    background-color: #edefee;
}

.portfolio-description {
    display: flex;
    font-weight: 300;
    margin: 3% 30%;
}

.content {
    display: grid;
    grid-template-columns: repeat(auto-fit, 50vh);
    justify-content: center;
    column-gap: 5vh;
    row-gap: 8vh;
}

/* For screens smaller than 600px */
@media only screen and (max-width: 600px) {
    .portfolio {
        width: 100%;
    }

    .portfolio-description {
        max-width: 600px;
        font-size: 1rem;
        margin: 5% 10%;
        text-align: left;
    }
}

/* For screens between 600px and 1024px */
@media only screen and (min-width: 600px) and (max-width: 1024px) {
    .portfolio {
        width: 100%;
        text-align: center;
    }

    .portfolio-description {
        max-width: 600px;
        font-size: 1rem;
        margin: 3% 20%;
        text-align: left;
    }
}

/* For screens larger than 1024px */
@media only screen and (min-width: 1024px) {
    .content {
        width: 100%;
    }
}