.contact {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 5vh 0vh;
    align-items: center;
    background-color: #edefee;
}

.contact-title {
    color: black;
}

.contact-container {
    width: 50%;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    margin-top: 5%;
}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background-color: white;
    color: black;
    border: transparent;
    font-family: "Noto Sans"
}

.contact-button {
    background: #b82cb8;
    color: white;
    font-size: 17px;
    font-weight: bold;
    width: 200px;
    padding: 10px 20px;
    border-radius: 18px;
    border: transparent;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.contact-button:hover {
    background-color: #FFD700;
    color: black;
}

/* For mobile phone */
@media only screen and (max-width: 600px) {
    .contact {
        width: 100%;
        height: auto;
        text-align: center;
    }
    .contact-container {
        text-align: center;
        width: 100%;
    }

    .form {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    .border-box {
        box-sizing: border-box;
    }

    .contact-button {
        align-self: center;
    }
}

/* For iPhone X, Xs, XR, and 11 */
@media only screen 
    and (min-width: 414px) 
    and (min-height: 896px) 
    and (-webkit-device-pixel-ratio: 2) { 
        .contact {
            height: auto;
        }
    }

/* For iPad */
@media only screen 
    and (min-width: 768px) 
    and (max-width: 1024px) {
        .contact-container {
            width: 100%;
        }
        
        .form {
            width: 80%;
            margin-left: auto;
            margin-right: auto;
        }

        .border-box {
            box-sizing: border-box;
        }
    }