.chart-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: row;
  justify-content: center; /* center the grid horizontally */
  gap: 10px;
  width: 100%;
  box-sizing: border-box; /* include padding and borders in the width and height */
  padding: 2em;
}

.chart {
  width: 100%;
  height: 20em;
  margin-bottom: 2em;
}

/* Mobile Devices */
@media (max-width: 768px) {
  .chart-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .chart {
    width: 100%;
    height: 10em;
  }
}


/* ipad */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .chart-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1px;
    box-sizing: border-box; 
    padding: 5em;
  }

  .chart {
    width: 100%;
    height: 25em;
    margin-bottom: 2em;
  }
}
