.brand {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 80vh;
}

.name {
    font-family: "Libre Baskerville";
    font-size: 5vw;
    color: black;
}

.title {
    font-size: 2vw;
    color: black;
    margin-top: 2rem;
}

/* Icons section */
.social-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

.icons  {
    color: black;
    margin: 0 20px;
    -webkit-transition: color 0.7s;
    -moz-transition:    color 0.7s;
    -ms-transition:     color 0.7s;
    -o-transition:      color 0.7s;
    transition:         color 0.7s;
}

.icons:hover {
    color: #FFD700;
}

/* Skill section 2 */
.skill-section {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    margin: 0 auto;
    max-width: fit-content;
    /* max-width: 800px; */
  }
  
  .skill-category {
    font-weight: bold;
    /* text-align: center; */
  }
  
  .skill-list {
    /* list-style: none; */
    list-style:disc;
    margin: 0;
    padding: 0;
  }
  
  .skill-item {
    margin-bottom: 10px;
  }





/* Skill Section */
.skills {
    color: black;
}

.skills-container {
    margin: 5%;
    border: 2px solid rgb(195, 193, 193);
    border-radius: 20px;
}

.skills-title {
    margin-top: 2%;
}

.skills-info {
    padding: 2vw 20vw;
    color: #616888;
}



/* For mobile phone */
@media only screen and (max-width: 600px) {

    .home-heading1 {
        font-size: 7vh;
    }   

    .home-heading2 {
        font-size: 2.5vh;
    }
}

/* For iPhone X, Xs, XR, and 11 */
@media only screen 
    and (min-width: 414px) 
    and (min-height: 896px) 
    and (-webkit-device-pixel-ratio: 2) { 
        .home-heading1 {
            font-size: 5vh;
            top: 10%;
        }
        .home-heading2 {
            font-size: 2vh;
            top: 16%;
        }
        .social-icons {
            top: 20%;
        }
    }

/* For iPhone 12 and 13 */
@media only screen 
    and (min-width: 375px) 
    and (min-height: 812px) 
    and (-webkit-device-pixel-ratio: 3) {
        .home-heading1 {
            font-size: 5vh;
        }
    }

/* For Galaxy Fold   */
@media(max-width: 320px){ 
    .home-heading1 {
        font-size: 5vh;
    }
}
/* For iPad */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .home-heading1 {
        font-size: 8vh;
    }

    .home-heading2 {
        font-size: 3vh;
        top: 20%;
    }
    
    .social-icons {
        top: 25%;
    }

    .icons {
        font-size: 2em;
    }
}