.navbar {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 3vh;
    height: 6vh;
    background: transparent;
}

.directories {
    list-style: none;
    padding: 0;
    margin: 0;
}
  
.directories li {
    display: inline-block;
    position: relative;
    padding: 10px;
}
  
.directories a {
    display: inline-block;
    position: relative;
    text-decoration: none;   
}

.directories a::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 0;
    background-color: black;
    transition: width 0.5s ease;
}

.directories a:hover::before,
.directories li a.active::before {
    width: 100%;
    left: 0;
}

.navbar .directories a {
    color: black;
}

.navbar .directories li {
    margin: auto 2vh;
}

.navbar .menu-icon {
    height: 4vh;
    margin-left: auto;
}

.navbar .t-logo {
    height: 4vh;
}

.navbar .mandy{
    max-width: 150px;
    width: 100%;
}

.navbar .mandy:hover {
    cursor: pointer;
}

.navbar .menu-icon:hover {
    cursor: pointer;
}

/* For screens smaller than 600px */
@media only screen and (max-width: 600px) {
    
    /* Hide the horizontal directories */
    .navbar .directories {
        display: none;
    }
    
    .navbar .mobile-navbar {
        position: fixed;
        top: 0;
        background-color: #f5f5f7;
        width: 70%;
        height: 100%;
        transition: 0.6s;
        z-index: 1;
        padding: 4vh 0vh 1vh 0vh;
    }
    
    .navbar .mobile-directories {
        margin-top: 5vh;
        list-style-type: none;
    }

    .navbar .mobile-directories a {
        text-decoration: none;
        color: black;
    }

    .navbar .mobile-directories li {
        margin-top: 4vh;
        margin-left: 15%;
        background-color: transparent;  
        transition: background-color 0.3s ease-in-out;
    }

    .navbar .mobile-directories li:hover{
        background-color: #b82cb8;
        cursor: pointer;
    }


    .navbar .mobile-directories li:hover > a{
        color: white;
    }
}

/* For desktop */
@media only screen and (min-width: 600px) {
    .navbar .directories {
        display: flex;
        list-style-type: none;
        margin-left: auto;
    }

    .navbar .menu-icon {
        display: none;
    }

    .navbar .mobile-navbar {
        display: none;
    }
}