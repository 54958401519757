.card {
    height: 100%;
    width: 50vh;
    display: flex;
}

.card-img {
    height: 35vh;
    width: 100%;
    object-fit: cover;
    opacity: 1;
    overflow: hidden;
    transition: 0.5s ease;
    backface-visibility: hidden;
}

.card-img:hover {
    opacity: 0.5;
    transition: 600ms;
}

.overlay {
    position: absolute;
    height: 35vh;
    width: 50vh;
    transition: 0.5s ease;
    opacity: 0;
    background-color: rgba(0,0,0,0.7);
}

.overlay-icon {
    display: block;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -15px;
    margin-top: -15px;
    color: white;
}

.card:hover .card-img {
    opacity: 0.3;
}

.card:hover .overlay {
    opacity: 1;
}

.overlay-content {
    text-align: left;
    padding: 20px;
    color: white;
}

/* For mobile devices */
@media only screen and (max-width: 600px) {
    .card {
        height: auto;
        width: 100%;
        flex-direction: column;
    }
      
    .card-img {
        height: auto;
        width: 50vh;

    }
      
    .overlay {
        display: none;
    }
      
    .overlay-icon {
        display: none;
    }
}
      
/* For tablets and smaller laptops with screens between 600px and 1024px */
@media only screen and (min-width: 600px) and (max-width: 1024px) {
    .card {
        width: auto;
    }
}
      