.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
    z-index: 1;
}

.modal.show {
    opacity: 1;
    pointer-events: visible;
}

.modal.show .modal-content {
    transform: translateY(0);
}

.modal-content {
    width: 500px;
    background-color: #fff;
    transform: translateY(-200px);
    transition: all 0.3s ease-in-out;
}

.modal-image {
    width: 500px;
}

.modal-header, .modal-footer {
    padding: 10px;
    text-align: left;
}

.modal-footer {
    background-color: black;
    color: white;
}

.modal-link {
    color: white;
    font-weight: bold;
    font-size: 0.8rem;
    letter-spacing: 2px;
    margin-right: 5%;
    text-decoration: none;
    text-transform: uppercase;
}

.modal-button {
    color: white;
    font-weight: bold;
    letter-spacing: 2px;
    font-size: 0.8rem;
    border: none;
    cursor: pointer;
    font-family: "noto sans";
    text-transform: uppercase;
    background-color: transparent;
}

.modal-title {
    margin: 0;
    font-weight: bold;
    font-size: 1em;
}

.modal-body {
    padding: 10px;
    text-align: left;
}

.modal-roles-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 1em;
    margin-bottom: 1em;
    padding: 5px 0;
    align-items: center;
    letter-spacing: 1px;
    font-size: 11px;
    color: #000000;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}

.modal-roles-container > p {
    margin-right: 5px;
}

.gear-icon {
    margin-right: 5px;
}

.modal-tool-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    letter-spacing: 1px;
    font-size: 11px;
    color: #808080;
}

.modal-tool-container > p {
    margin-right: 5px;
}

.modal-my-roles {
    padding: 1%;
}

.modal-tool{
    padding: 1%;
}

/* For mobile devices */
@media only screen and (max-width: 600px) {
    .modal-content, .modal-image {
        width: 260px;
    }

    .modal-body {
        font-size: 12px;
    }
}

/* For tablets and smaller laptops with screens between 600px and 1024px */
@media only screen and (min-width: 600px) and (max-width: 1024px) {
    .modal-content, .modal-image {
        width: 80%;
        height: auto;
    }

    .modal-body {
        font-size: 16px;
    }
}