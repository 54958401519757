.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 10rem;
}

.footer-logo {
    height: 5em;
    width: 5em;
    margin-bottom: 0.5em;
}

.footer .footer-logo:hover {
    cursor: pointer;
}

.footer-copyright {
    font-size: 12px;
    margin-top: 1em;
    font-family: 'Noto Sans', sans-serif;
    font-weight: 400;
}

/* Mobile Phone */
@media only screen and (max-width: 480px) {
    .footer-logo {
        width: 3em;
        height: 3em;
    }

    .footer-copyright {
        font-size: 10px;
    }
}

/* Tablets */
@media only screen and (min-width: 481px) and (max-width: 768px) {
    .footer-logo {
        width: 4em;
        height: 4em;
    }

    .footer-copyright {
        font-size: 11px;
    }
}

/* .footer {
    color: white;
    color: black;
    padding: 2vh 5%;
    background-color: aqua;
}

.footer-container {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
}

.footer-logo {
    padding: 5vh;
    height: 10vh;
}
flex parent
.footer-nav {
    display: flex;
    flex: 2 1 auto;             
    flex-wrap: wrap;
}
flex children
.footer-column {
    flex: 1 0 auto;
}

.footer-title {
    text-transform: uppercase;
    font-weight: bold;
    padding: 5px;
}

.footer-list {
    list-style: none;
    font-size: 12px;
}

.footer-list a {
    color: white;
    color: black;
    text-decoration: none;
    padding: 0 5px 0 5px;
}

.footer-copyright {
    padding: 3%;
    margin-left: 0.2em;
}

For mobile phone
@media only screen and (max-width: 600px) {
    .footer-container {
        display: flex;
        flex-flow: row wrap;
    }

    .footer-logo {
        display: none;
    }

    .footer-nav {
        flex-direction: column;
        display: flex;
        flex: 2 1 auto;
        flex-wrap: wrap;
    }
    
    .footer-column {
        padding: 3%;
    }
    
    To make the list horizontal
    .footer-list {
        display: flex;
        flex-wrap: wrap;
    }
    .footer-copyright {
        flex: 1 1 150px;
        display: flex;
        flex-direction: column;
    }
}

For iPad
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .footer-container {
        display: flex;
        flex-flow: row wrap;
    }

    .footer-logo {
        height: 10vh;
    }

    .footer-nav {
        flex-direction: column;
        display: flex;
        flex: 2 1 auto;
        flex-wrap: wrap;
    }

    .footer-column {
        padding: 3%;
    }

    .footer-list {
        display: flex;
        flex-wrap: wrap;
    }

    .footer-title {
        font-size: 1.5vh;
    }

    .footer-list li {
        font-size: 1.2vh;
        padding: 0 10px 0 10px;
    }

    .footer-copyright {
        display: flex;
    }
} */
